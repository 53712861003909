import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "auth",
    component: () => import("../views/LoginView.vue"),
    meta: {
      layout: 'auth'
    }
  },
  {
    path: "/favourites",
    name: "favourites",
    component: () => import("../views/FavouritesView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/:slug",
    name: "card",
    component: () => import("../views/CardView.vue"),
    meta: {
      requiresAuth: true
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token")) {
      next();
    } else {
      next({ name: "auth" });
    }
  } else {
    next();
  }
});

export default router;
