<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
<script>
import MainLayout from "@/layouts/default";
import AuthLayout from "@/layouts/auth";

export default {
  name: "App",
  components: { MainLayout, AuthLayout },
  computed: {
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },  
}
  // user-select: none;
</script>
<style lang="scss">
html, body {
  -webkit-user-drag: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #005587;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
