<template>
 <div class="container">
  <header class="header">
   <router-link to="/" class="header-logo">
    <img :src="require('@/assets/logo.png')" alt="logo" @click="goMain" />
   </router-link>
   <div class="header__search dshow">
    <input type="test" v-model="search" @input="searchProduct" />
    <button>
     <img :src="require('@/assets/icons/search.svg')" alt="search" />
    </button>
   </div>
   <div class="header-icon dshow" @click="openExport = !openExport">
     <img :src="require('@/assets/icons/arrow-down.png')" alt="filter" />
   </div>
   <div class="header-icon dshow" @click="openModal = !openModal">
     <img :src="require('@/assets/icons/filter.svg')" alt="filter" />
   </div>
   <div class="header-left">
    <div class="header__filial dshow">
      <div class="header__filial-title" @click="openFilial = !openFilial">
        <p>{{actDeparts.title}}</p>
        <img :class="{opened: openFilial}" :src="require('@/assets/icons/back.svg')" alt="back" />
      </div>
      <Transition name="filial">
        <div class="header__filial-content" v-click-outside="closeFilial" v-if="openFilial">
          <div class="header__filial-content-elem" v-for="item in getFilters.departments" :key="item.code">
            <input type="radio" :id="item.code" :value="item" v-model="departs" @click="selectBranch(item)" />
            <label :for="item.code">{{item.title}}</label>
          </div>
          <p class="clearBr" @click="clearBr" v-if="departs">{{ $t("clear") }}</p>
        </div>
      </Transition>
    </div>
    <router-link to="/favourites" class="header-icon">
      <img :src="require('@/assets/icons/heart.svg')" alt="heart" />
      <span class="header-icon-count" v-if="getCards?.data?.length">{{ getCards?.data?.length }}</span>
    </router-link>
    <div class="header__filial dshow">
      <div class="header__filial-title" @click="openLang = !openLang">
        <p v-if="lang === 'ru'">RU</p>
        <p v-if="lang === 'en'">EN</p>
        <img :class="{opened: openLang}" :src="require('@/assets/icons/back.svg')" alt="back" />
      </div>
      <Transition name="filial">
        <div class="header__filial-content lang" style="min-width: auto;" v-click-outside="closeLang" v-if="openLang">
          <div class="header__filial-content-elem">
            <input type="radio" id="lang1" @change="changeLang" v-model="lang" value="ru" name="lang" checked />
            <label for="lang1">RU</label>
          </div>
          <div class="header__filial-content-elem">
            <input type="radio" id="lang2" @change="changeLang" v-model="lang" value="en" name="lang" />
            <label for="lang2">EN</label>
          </div>
        </div>
      </Transition>
    </div>
    <div class="header__filial dshow">
      <div class="header__filial-title" @click="openExit = !openExit">
        <img :src="require('@/assets/icons/log-out.svg')" alt="user" />
      </div>
      <Transition name="filial">
        <div class="header__filial-content lang" style="align-items: center;" v-click-outside="closeExit" v-if="openExit">
          <img :src="require('@/assets/logo.png')" alt="logo" />
          <p>Иван Иванов. A</p>
          <button @click="exitUser">{{ $t("exit") }}</button>
        </div>
      </Transition>
    </div>
   <div class="header-icon mshow" @click="openExport = !openExport">
     <img :src="require('@/assets/icons/arrow-down.png')" alt="filter" />
   </div>
    <div class="header-icon mshow" @click="openModal = !openModal">
      <img :src="require('@/assets/icons/filter.svg')" alt="filter" />
    </div>
    <div class="header-burger mshow">
       <input type="checkbox" id="checkbox" :checked="openBurger" @input="openBurger = !openBurger">
       <label for="checkbox" class="toggle">
           <div class="bars" id="bar1"></div>
           <div class="bars" id="bar2"></div>
           <div class="bars" id="bar3"></div>
       </label>
    </div>
    <Transition name="bounce">
      <div class="burger mshow" v-if="openBurger">
        <!-- v-click-outside="closeBurger" -->
        <div class="burger__content">
          <router-link to="/" class="burger__content-logo">
            <img :src="require('@/assets/logo.png')" alt="logo" />
          </router-link>
          <div class="burger__content-search">
            <input type="test" v-model="search"/>
            <img style="cursor: pointer;" :src="require('@/assets/icons/search_gray.svg')" alt="search" @click="searchProduct1" />
          </div>
          <div class="burger__content__branch">
            <p>{{ $t("branch") }}</p>
            <div class="burger__content__branch-list" v-if="getFilters?.departments">
              <div class="burger__content__branch-list-elem" v-for="item in getFilters.departments" :key="item.code">
                <input type="radio" :id="item.code" :value="item" v-model="departs" @click="selectBranch(item)" />
                <label :for="item.code">{{item.title}}</label>
              </div>
              <p class="clearBr" @click="clearBr" v-if="departs">{{ $t("clear") }}</p>
            </div>
          </div>
          <div class="burger__content-exit">
            <img :src="require('@/assets/icons/log-out.svg')" alt="log-out" />
            {{ $t("exit") }}
          </div>
        </div>
      </div>
    </Transition>
   </div>
  </header>
  <div class="modalfilter" v-if="openExport">
    <Transition name="filial">
      <div class="modalfilter__content conts" v-click-outside="closeExport" v-if="openExport">
        <div class="modalfilter__content-top">
          <p>{{ $t("selectdate") }}</p>
          <img :src="require('@/assets/icons/close.svg')" alt="close" @click="openExport = false" />
        </div>
        <div class="modalfilter__content-selects">
          <v-select class="modalfilter__content-selects-elem" v-if="getDepartments" :options="getDepartments" v-model="branch_id" label="title" placeholder="Филиал" />
          <v-select class="modalfilter__content-selects-elem" :disabled="manufacturer_id ? true : false" v-if="getSupplers" @option:selecting="suppSelect(true)" :options="getSupplers" v-model="suppler_id" label="title" placeholder="Поставщик" />
          <v-select class="modalfilter__content-selects-elem" :disabled="suppler_id ? true : false" v-if="getManufacturers"  @option:selecting="suppSelect(false)" @input="suppler_id = ''" :options="getManufacturers" v-model="manufacturer_id" label="title" placeholder="Производитель" />

        </div>
        <div class="modalfilter__content-content conts-content">
          <VDatePicker v-model="export_date" :disabled-dates="disabledDates" title-position="left" expanded />
        </div>
        <div class="flexxx" style="display: flex; align-items: stretch; justify-content: center; gap: 15px;">
          <div class="modalfilter__content-button" style="margin: 0;" @click="downloadReport">{{ $t("download") }}</div>
        </div>
      </div>
    </Transition>
  </div>
  <div class="modalfilter" v-if="openModal">
    <Transition name="filial">
      <div class="modalfilter__content" v-click-outside="closeModal" v-if="openModal">
        <div class="modalfilter__content-top">
          <p>{{ $t("filter") }}</p>
          <img :src="require('@/assets/icons/close.svg')" alt="close" @click="openModal = false" />
        </div>
        <div class="modalfilter__content-content">
          <div class="modalfilter__content-content-left">
            <p>{{ $t("manufacturer") }}:</p>
            <div class="modalfilter__content-content-left-elem" style="width: 100%;">
              <input type="text" :placeholder="`${$t('search')}...`" v-model="manu_search" class="searchInp" />
            </div>
            <div class="modalfilter__content-content-left-elem">
              <input type="radio" id="notprod111" name="notprod111" value="0" v-model="maunfact" />
              <label for="notprod111">{{ $t("notCheck") }}</label>
            </div>
            <div class="flexdir">
              <div class="modalfilter__content-content-left-elem" v-for="item in manus" :key="item">
                <input type="radio" :id="item" :name="item" :value="item" v-model="maunfact" />
                <label :for="item">{{ item }}</label>
              </div>
            </div>
          </div>
          <div class="modalfilter__content-content-left">
            <p>{{ $t("suppler") }}:</p>
            <div class="modalfilter__content-content-left-elem" style="width: 100%;">
              <input type="text" :placeholder="`${$t('search')}...`" v-model="supple_search" class="searchInp" />
            </div>
            <div class="modalfilter__content-content-left-elem">
              <input type="radio" id="notprod11" name="notprod11" value="0" v-model="supple" />
              <label for="notprod11">{{ $t("notCheck") }}</label>
            </div>
            <div class="flexdir">
              <div class="modalfilter__content-content-left-elem" v-for="item in suppls" :key="item">
                <input type="radio" :id="item.title" :name="item.title" :value="item.id" v-model="supple" />
                <label :for="item.title">{{ item.title }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="flexxx" style="display: flex; align-items: stretch; justify-content: center; gap: 15px;">
          <div class="modalfilter__content-button" style="margin: 0;" @click="searchFilters">{{ $t("search") }}</div>
          <p style="width: 120px" class="clearBr" @click="clearBF">{{ $t("clear") }}</p>
        </div>
      </div>
    </Transition>
  </div>
 </div>
</template>
<script>
import SelectBlock from './UI/SelectBlock.vue';
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    data: () => ({
        search: '',
        actDeparts: {},
        branch: '',
        departs: null,
        maunfact: '0',
        manu_search: '',
        supple_search: '',
        suppler_id: '',
        branch_id: '',
        manufacturer_id: '',
        export_date: new Date(),
        supple: '0',
        openFilial: false,
        openExport: false,
        openLang: false,
        openModal: false,
        openExit: false,
        openBurger: false,
        branch_list: [
            {
                label: 'г. Алматы',
                id: 1
            },
            {
                label: 'г. Астана',
                id: 2
            },
            {
                label: 'г. Шымкент',
                id: 3
            },
            {
                label: 'г. Актау',
                id: 4
            },
        ],
        lang: localStorage.getItem("lang") || "ru",
        lang_list: [
            {
                label: 'RU',
                id: 'ru'
            },
            {
                label: 'EN',
                id: 'en'
            },
        ]
    }),
    computed: {
      ...mapGetters(["getCards", "getAuth", "getFilters", "getSupplers", "getDepartments", "getManufacturers"]),
      disabledDates() {
        return [{ start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000), end: null }]
      },
      manus() {
        if (this.getFilters) {
          return this.getFilters.manufacturers.filter(item => item.toLowerCase().includes(this.manu_search.toLowerCase()))
        } else {
          return this.getFilters.manufacturers
        }
      },
      suppls() {
        if (this.getFilters) {
          return this.getFilters.suppliers.filter(item => item.title.toLowerCase().includes(this.supple_search.toLowerCase()))
        } else {
          return this.getFilters.suppliers
        }
      }
    },
    created() {
      this.actDeparts.title = this.$t("selectBranch")
      if (this.getAuth) {
        this.getFavourites()
        this.getFiltersProducts()
      }
    },
    mounted() {
      setTimeout(() => {
        const query = { ...this.$route.query };
        if (query.hasOwnProperty('search')) {
          this.search = query.search
        }
        if (query.hasOwnProperty('supplier_id')) {
          this.supple = query.supplier_id
        }
        if (query.hasOwnProperty('manufacturer')) {
          this.maunfact = query.manufacturer
        }
      }, 200);
    },
    methods: {
      ...mapActions(["logoutUser", "getFavourites", "getFiltersProducts"]),
      suppSelect(bool) {
        bool ? this.manufacturer_id = '' : this.suppler_id = ''
      },
      changeLang() {
        this.openLang = false
        localStorage.setItem("lang", this.lang);
        location.reload();
      },
      clearBr() {
        this.actDeparts = {
          title: this.$t("selectBranch")
        }
        const query = { ...this.$route.query };
        delete query.department_code;
        this.$router.push({ name: "home", query });
        localStorage.removeItem("depart")
        this.openFilial = false
        this.openBurger = false
        this.departs = false
      },
      clearBF() {
          this.supple = "0"
          this.maunfact = "0"
          const query = { ...this.$route.query };
          delete query.supplier_id;
          delete query.manufacturer;
          this.$router.push({name: "home", query: query})
          this.openModal = false
      },
      searchFilters() {
        const query = { ...this.$route.query };
        if (this.maunfact !== "0") {
          query.manufacturer = this.maunfact;
        } else {
          delete query.manufacturer;
        }
        if (this.supple !== "0") {
          query.supplier_id = this.supple;
        } else {
          delete query.supplier_id;
        }
        delete query.search;
        this.search = ""
        this.$router.push({ name: "home", query });
        this.openModal = false;
      },
      selectBranch(value) {
        this.actDeparts = value
        this.openFilial = false
        this.openBurger = false
        localStorage.setItem("depart", JSON.stringify(value))
        const query = { ...this.$route.query };
          query.department_code = value.code;
        this.$router.push({ name: "home", query: query });
      },
      searchProduct() {
        if (this.search.length > 2) {
          const query = { ...this.$route.query };
          query.search = this.search
          delete query.supplier_id;
          delete query.manufacturer;
          this.$router.push({name: "home", query: query})
        } else {
          const currentQuery = { ...this.$route.query };
          delete currentQuery.search;
          this.$router.push({ name: "home", query: currentQuery });
        }
      },
      searchProduct1() {
          const query = { ...this.$route.query };
          if (this.search.length) {
            query.search = this.search
          } else {
            delete query.search;
          }
          delete query.supplier_id;
          delete query.manufacturer;
          this.$router.push({name: "home", query: query})
          this.openBurger = false
      },
      selectedBranch(id) {
        this.branch = id
      },
      closeBurger() {
        this.openBurger = false
      },
      goMain() {
        this.maunfact = "0"
        this.supple = "0"
        this.search = ""
        this.actDeparts = {
          title: this.$t("selectBranch")
        }
        this.departs = null
      },
      closeModal() {
        this.openModal = false
      },
      closeFilial() {
        this.openFilial = false
      },
      closeExport() {
        this.openExport = false
      },
      closeLang() {
        this.openLang = false
      },
      closeExit() {
        this.openExit = false
      },
      async downloadReport() {
        if (this.branch_id === "") {
          this.$toaster.warning(this.$t('selectbranch'))
        } else if (this.manufacturer_id === "" && this.suppler_id === "") {
          this.$toaster.warning(this.$t('selectmansup'))
        } else {
          const dateObject = this.export_date;
          const year = dateObject.getFullYear();
          const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
          const day = ('0' + dateObject.getDate()).slice(-2);
          const formattedDate = year + '-' + month + '-' + day;
          let sendObject = {}
          sendObject.date = formattedDate
          sendObject.department_code = this.branch_id.code
          if (this.manufacturer_id !== "") {
            sendObject.manufacturer = this.manufacturer_id
            sendObject.supplier_id = 0
          }
          if (this.suppler_id !== "") {
            sendObject.supplier_id = this.suppler_id.id
          }
          await this.$axios.post("report", sendObject, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then(response => {
            window.open(response.data.redirect_to, '_blank');
            this.suppler_id = ''
            this.branch_id = ''
            this.manufacturer_id = ''
            this.openExport = false
          })
          .catch(err => {
            this.$toaster.error(err.response.data)
          })
        }
      },
      async exitUser() {
       await this.logoutUser() 
        if (!this.getAuth) {
          this.$router.push("/login")
        }
      }
    },
    components: { SelectBlock },
    watch: {
      manufacturer_id() {
        console.log(this.manufacturer_id);
      },
      openBurger() {
        if (this.openBurger) {
          document.documentElement.classList.add('noscroll');
        } else {
          document.documentElement.classList.remove('noscroll');
        }
      },
      openModal() {
        if (this.openModal) {
          document.documentElement.classList.add('noscroll');
        } else {
          document.documentElement.classList.remove('noscroll');
        }
      }
    }
}
</script>
<style lang="scss" scoped>
 .header {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-logo {
   max-width: 177px;
   max-height: 73px;
   img {
    width: 100%;
    height: 100%;
   }
   @media screen and (max-width: $tablet) {
    img {
      width: 159px;
      height: 66px;
    }
   }
   @media screen and (max-width: $mobileSE) {
    img {
      width: 132px;
      height: 50px;
    }
   }
  }
  &__filial {
    position: relative;
    &-title {
      display: flex;
      align-items: center;
      gap: 5px;
      img {        
        transition: all 0.5s ease;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &-content {
      width: fit-content;
      position: absolute;
      right: 0;
      top: calc(100% + 23px);
      display: flex;
      min-width: 200px;
      flex-direction: column;
      padding: 22px 30px;
      gap: 15px;
      border-radius: 5px;
      background: #FFF;
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.16);
      &-elem {
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 10px;
        input {
          accent-color: #005587;
          width: 20px;
          height: 20px;
          margin: 0;
          padding: 0;
        }
      }
      img {
        width: 95px;
        height: 39px;
      }
      button {
        background: transparent;
        outline: none;
        padding: 4px 41px 8px 42px;
        border-radius: 5px;
        color: #005587;
        border: 1px solid #005587;
        &:hover {
          cursor: pointer;
          background: #005587;
          color: white;
        }
      }
      p {
        font-size: 16px;
        color: #7D7D7D;
      }
    }
    .lang {
      right: 0;
      left: auto;
    }
  }
  &-branch {
    width: 167px;
  }
  &__search {
   display: flex;
   align-items: center;
   height: 40px;
   border-radius: 5px;
   border: 1px solid rgba(0, 0, 0, 0.10);
   overflow: hidden;
   input {
    padding: 0 25px;
    border: none;
    height: 100%;
   }
   button {
    background: #005587;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 35px;
   }
   @media screen and (max-width: 1300px) {
     width: 30vw;
     input {
      width: 80%;
     }
   }
  }
  &-icon {
    position: relative;
    display: flex;
    align-items: center;
    span {
      top: -3px;
      left: 60%;
      background: #005587;
      color: white;
      font-size: 10px;
      border-radius: 50%;
      position: absolute;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.07);
      transition: all .2s ease;
    }
  }
  &-left {
    display: flex;
    align-items: center;
    gap: 50px;
    @media screen and (max-width: 1200px) {
      gap: 30px;
    }
    @media screen and (max-width: $tablet) {
      gap: 20px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  &-burger {
    position: relative;
    z-index: 100;
    margin-left: -23px;
    justify-content: flex-end;
    #checkbox {
      display: none;
    }    
    .toggle {
      position: relative;
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      transition-duration: .3s;
      transform: scale(0.42);
      transform-origin: right center;
    }    
    .bars {
      width: 100%;
      height: 4px;
      background-color: #005587;
      transition-duration: .3s;
    }    
    #checkbox:checked + .toggle .bars {
      margin-left: 13px;
    }    
    #checkbox:checked + .toggle #bar2 {
      transform: rotate(135deg);
      margin-left: 0;
      transform-origin: center;
      transition-duration: .3s;
    }    
    #checkbox:checked + .toggle #bar1 {
      transform: rotate(45deg);
      transition-duration: .3s;
      transform-origin: left center;
    }    
    #checkbox:checked + .toggle #bar3 {
      transform: rotate(-45deg);
      transition-duration: .3s;
      transform-origin: left center;
    }    
  }
 }
 .burger {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation: fadeIn 0.5s ease-in-out forwards, blurBackground 0.1s ease-in-out forwards;
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0);
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 50px 32px;
    height: fit-content;
    &-exit {
      margin-top: 25px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #005587;
      img {
        width: 24px;
        height: 24px;
      }
    }
    &-logo {
      width: 159px;
      height: 66px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-search {
      margin: 50px;
      display: flex;
      justify-content: space-between;
      border-radius: 10px;
      border: 1px solid rgba(0, 85, 135, 0.50);
      background: #FFF;
      padding: 7px 14px;
      position: relative;
      width: 100%;
      input {
        border: none;
        outline: none;
        padding: 0;
        font-size: 12px;
        height: 18px;
        width: 85%;
      }
      img {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 24px;
        top: 4px;
      }
    }
    &__branch {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      &-list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        &-elem {
          display: flex;
          align-items: center;
          gap: 10px;
          input {
            width: 20px;
            height: 20px;
            margin: 0;
            accent-color: #005587;
            padding: 0;
          }
        }
      }
    }
  }  
 }
 .bounce-enter-active { 
    animation: bounce-in .5s; 
  } 
  .bounce-leave-active { 
    animation: bounce-in .5s reverse; 
  } 
  
  @keyframes bounce-in { 
    0% { 
      top: -80%; 
    } 
    100% { 
      right: -130%; 
    } 
  }
  @keyframes fadeIn {
    0% {
      background: rgba(0, 0, 0, 0);
    }
    100% {
      background: rgba(0, 0, 0, 0.10);
    }
  }
  
  @keyframes blurBackground {
    0% {
      backdrop-filter: blur(0);
    }
    100% {
      backdrop-filter: blur(2px);
    }
  }
  
 .filial-enter-active { 
  animation: filial-in .3s; 
} 
.filial-leave-active { 
  animation: filial-in .3s reverse; 
} 

@keyframes filial-in { 
  0% { 
    top: 80%; 
  } 
}
.opened {
  transform: rotate(180deg);
  transition: all 0.5s ease;
}
.modalfilter {
  position: fixed;
  z-index: 105;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(2px);
  &__content {
    width: 57%;
    max-width: 100%;
    max-height: 90vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
    border-radius: 5px;
    background: #FFF;
    padding: 40px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.16);
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 25px;
      img {
        cursor: pointer;
      }
    }
    &-selects {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      row-gap: 15px;
      column-gap: 30px;
      &-elem { 
          width: 100%;
      }
    }
    &-content {
      font-size: 14px;
      display: flex;
      align-items: flex-start;
      height: 60vh;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      padding-top: 10px;
      .flexdir {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        margin-top: 10px;
        :first-child {
          margin-top: 3px;
        }
      }
      &-left {
        width: 46%;
        height: 100%;
        display: flex;
        flex-direction: column;
        &-elem {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 15px;
          p {
            font-size: 14px;
            margin-bottom: 5px;
          }
          input {
            accent-color: #005587;
            width: 20px;
            height: 20px;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
    &-button {
      display: flex;
      width: 186px;
      padding: 7px 0px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: #FFF;
      font-size: 14px;
      background: #005587;
      margin: 0 auto;
      &:hover {
        cursor: pointer;
        background: #05466b;
      }
    }
  }
  .conts {
    width: fit-content;
    gap: 15px;
    padding: 40px 83px 79px 83px;
    &-content {
      border-top: none;
      height: auto;
      margin-bottom: 20px;
    }
    @media screen and (max-width: $tablet) {
      width: 90%;
      padding: 30px;
    }
  }
  @media screen and (max-width: $tablet) {
    &__content {
      gap: 18px;
      width: 85%;
      padding: 30px;
      &-top {
        padding-bottom: 17.5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 20px;
        img {
          width: 20px;
          height: 20px;
        }
      }
      &-content {
        border-top: none;
        flex-direction: column;
        border-radius: 10px;
        gap: 30px;
        &-left {
          border-top: 1px solid rgba(0, 0, 0, 0.15);
          overflow-y: auto;
          padding-top: 10px;
          width: 100%;
          &:first-child {
            padding-top: 0px;
            border-top: none;
          }
        }
      }
      &-button {
        margin-top: 12px;
      }
    }
  }
  @media screen and (max-width: $mobileSE) {
    &__content {
      padding: 25px;
      &-top {
        padding-bottom: 14.5px;
        font-size: 18px;
      }
      &-content {
        font-size: 13px;
      }
      &-button {
        margin-top: 12px;
        padding: 5px 0;
      }
    }
  }
}
.searchInp {
  width: 100% !important;
  padding: 12px !important;
}

.clearBr {
  border: 1px solid #005587;
  border-radius: 10px;
  color: #005587 !important;
  display: flex;
  justify-content: center;
  padding: 5px;
  width: 100%;
  &:hover {
    cursor: pointer;
    background: #005587;
    color: white !important;
  }
}
</style>