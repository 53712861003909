<template>
 <footer class="footer container">
  <div class="footer-logo">
    <img :src="require('@/assets/logo.png')" alt="logo" />
  </div>
  <div class="footer__info">
    <div class="footer__info-left">
        <h3>{{ info.title }}</h3>
        <p v-for="(item, index) in info.list" :key="index">{{ item }}</p>
    </div>
    <div class="footer__info-right">
        <a :href="`mailto:${elem}`" class="footer__info-right-elem" v-for="elem in contacts" :key="elem">
            <img :src="require('@/assets/icons/mail.svg')" alt="mail" />
            <p>{{ elem }}</p>
        </a>
    </div>
  </div>
 </footer>
</template>
<script>
export default {
 data: () => ({
    info: false,
    contacts: ["Yevgeniy.Tushiyev@Kazminerals.com", "Ruslan.Kospakhov@Kazminerals.com", "Artem.Dmitriev@Kazminerals.com"]
 }),
 created() {
    this.info = {
        title: this.$t("info"),
        list: [this.$t("oferta"), this.$t("confirm"), this.$t("personal")]
    }
 }
}
</script>
<style lang="scss" scoped>
 .footer {
    border-top: 1px solid #005587;
    display: flex;
    align-items: center;
    gap: 20vw;
    padding: 30px 0;
    text-align: left;
    &-logo {
        width: 209px;
        img {
            width: 100%;
        }
        
        @media screen and (max-width: $tablet) {
            img {
            width: 159px;
            height: 66px;
            }
        }
    }
    &__info {
        display: flex;
        gap: 150px;
        align-items: stretch;
        &-left {
            h3 {
                margin-bottom: 18px;
                color: #005587 !important;
                font-weight: 800;
                text-transform: uppercase;
                font-size: 14px;
                letter-spacing: 3.64px;
            }
            p {
                margin-top: 11px;
                font-size: 14px;
                color: #005587 !important;
            }
        }
        &-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-elem {
                display: flex;
                align-items: center;
                gap: 10px;
            }
            p, a {
                color: #005587 !important;
                text-decoration: none;
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: $tablet) {
        flex-direction: column;
        align-items: stretch;
        gap: 30px;
        padding-bottom: 40px;
        &__info {
            flex-direction: column;
            gap: 30px;
            &-right {
                gap: 39px;
                p, a {
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: $mobileSE) {
        &__info {
            &-right {
                gap: 20px;
                p, a {
                    font-size: 12px;
                }
            }
        }
    }
 }
</style>