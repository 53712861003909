import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    auth: localStorage.getItem("access_token") ? true : false,
    card: false,
    products: false,
    filters: false,
    supplers: false,
    departments: false,
    manufacturers: false,
  },
  getters: {
    getCards: (state) => state.card,
    getAuth(state) {
      return state.auth;
    },
    getProducts(state) {
      return state.products;
    },
    getFilters(state) {
      return state.filters;
    },
    getSupplers(state) {
      return state.supplers;
    },
    getDepartments(state) {
      return state.departments;
    },
    getManufacturers(state) {
      return state.manufacturers;
    },
  }, 
  mutations: {
    SET_CARD(state, card) {
      state.card = card;
    },
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    SET_FILTERSPROD(state, filters) {
      state.filters = filters;
    },
    SET_SUPPLERS(state, supplers) {
      state.supplers = supplers;
    },
    SET_DEPARMENTS(state, departments) {
      state.departments = departments;
    },
    SET_MANUFACTURERS(state, manufacturers) {
      state.manufacturers = manufacturers;
    },
  },
  actions: {
    async getFiltersProducts({ commit }) {
      await axios.get("products", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }).then(res => {
        commit("SET_PRODUCTS", res.data.data.products)
        commit("SET_FILTERS", res.data.data.filters)
        commit("SET_SUPPLERS", res.data.data.filters.suppliers)
        commit("SET_DEPARMENTS", res.data.data.filters.departments)
        commit("SET_MANUFACTURERS", res.data.data.filters.manufacturers)
      })
    },
    async getFavourites({ commit }) {
      await axios.get("favorite", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }).then(res => {
        commit("SET_CARD", res.data)
      })
    },
    async addToCard({dispatch}, id) {
      await axios.post("favorite/add", {
        product_id: id
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }).then(res => {
        dispatch("getFavourites")
      })
    },
    async removeCard({dispatch}, id) {
      await axios.post("favorite/remove", {
        product_id: id
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }).then(res => {
        dispatch("getFavourites")
      })
    },
    async login({ commit }, data) {
      await axios.post("auth/login", data).then(res => {
        localStorage.setItem("access_token", res.data.token)
        commit("SET_AUTH", true);
      }).catch(err => {
        localStorage.removeItem("access_token")
      })
    },
    async logout({ commit }) {
        localStorage.removeItem("access_token");
        commit("SET_AUTH", false);
    },
    async logoutUser({ commit }) {
      await axios.post("auth/logout", {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }).then(async => {
        localStorage.removeItem("access_token");
        commit("SET_AUTH", false);
      }).catch(err => {
      })
    },
  },
  modules: {},
});
