import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./store";
import i18n from "./i18n";
import vSelect from "vue-select";
import vue3StarRatings from "vue3-star-ratings";
import VueAwesomePaginate from "vue-awesome-paginate";
import { createToaster } from "@meforma/vue-toaster";
import vClickOutside from "click-outside-vue3";
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import "vue-select/dist/vue-select.css";
import "vue-awesome-paginate/dist/style.css";

const axiosInstance = axios.create({
    baseURL: "https://kmm-back.mydev.kz/api/",
});
axios.defaults.baseURL = "https://kmm-back.mydev.kz/api/";

// Интерцептор для перехвата ошибок 401 и 403
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Пользователь не авторизован, выполните логику выхода пользователя
        store.dispatch('logout'); // Ваш метод для выхода пользователя в Vuex
        router.push('/login'); // Перенаправьте пользователя на страницу входа
      } else if (error.response.status === 403) {
        // Доступ запрещен, выполните необходимые действия
        // Например, вы можете показать сообщение об ошибке
      }
    }
    return Promise.reject(error);
  }
);

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VCalendar, {})
  .use(vClickOutside)
  .use(VueAwesomePaginate)
  .component("v-select", vSelect)
  .component("vue3-star-ratings", vue3StarRatings);

app.config.globalProperties.$axios = { ...axiosInstance };
app.config.globalProperties.$toaster = createToaster({ position: "top-right" });
app.mount("#app");