<template>    
   <div class="vselect" :style="'max-width: ' + width">
    <div class="vselect-title" @click="open = !open">
      <p v-if="place">{{ place.label }}</p>
      <p v-else-if="currentValue">{{ currentValue.label }}</p>
      <p v-else>{{ $t("selectBranch") }}</p>
      <img :class="{opened: open}" :src="require('@/assets/icons/back.svg')" alt="back" />
    </div>
    <Transition>
      <div class="vselect__list" v-if="open">
        <div class="vselect__list-item" :class="{ disabled: currentValue?.id === elem.id }" @click="selected(elem)" v-for="elem in options" :key="elem.id">
          {{ elem.label }}
        </div>
      </div>
    </Transition>
   </div>
</template>
<script>
export default {
    data: () => ({
      open: false,
      place: ''
    }),
    props: {
      options: {
        type: Array,
        required: true
      },
      item: {
        type: Number
      },
      width: {
        type: String,
        default: 'auto'
      },
      id: {
        type: String,
        required: false,
      }
    },
    computed: {
      currentValue() {
        if (this.place) {
          return this.place
        }
        let current = this.options.find(item => item.id === this.id)
        if (current) {
          return current
        }
      }
    },
    methods: {
      selected(elem) {
        this.open = false,
        this.place = elem,
        this.$emit('selected', elem.id)
      }
    }
}
</script>
<style lang="scss" scoped>
  .vselect {
    position: relative;
    &-title {
      display: flex;
      align-items: center;
      gap: 5px;
      width: 100%;
      cursor: pointer;
    }
    img {
      transition: all 0.5s ease;
    }
    .opened {
      transform: rotate(180deg);
      transition: all 0.5s ease;
    }
    &__list {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      position: absolute;
      left: -10%;
      padding: 5px 0 10px 0;
      width: 110%;
      background: white;
      &-item {
        text-align: left;
        padding: 5px 0;
        padding-left: 10%;
        &:hover {
          cursor: pointer;
          color: white;
          background: #005587;
        }
      }
      .disabled {
        pointer-events: none;
        background: #b4b6b8;
      }
    }
  }
</style>