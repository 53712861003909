<template>
  <div class="main">
    <header-block />
    <div class="container main-content">
     <slot />
    </div>
    <footer-block />
  </div>
</template>
<script>
import HeaderBlock from '@/components/HeaderBlock.vue'
import FooterBlock from '@/components/FooterBlock.vue'
export default {
  components: { HeaderBlock, FooterBlock },
}
</script>
<style lang="scss" scoped>
 .main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  &-content {
    flex-grow: 1;
  }
 }
</style>