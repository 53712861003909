import { createI18n } from "vue-i18n";

let appLanguage = localStorage.getItem("lang") || "ru"
export default createI18n({
  warnHtmlInMessage: 'off',
  locale: appLanguage,
  fallbackLocale: "ru",
  messages: {
    ru: require("./locales/ru.json"),
    en: require("./locales/en.json"),
  },
});
